<template>
  <v-container fluid-xl class="py-12 px-xl-12" id="our-services">
    <v-row class="py-12">
      <v-col cols="12">
        <h1 class="secondary--text text-center">
          Nuestros productos para empresas
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="6"
        sm="6"
        md="4"
        lg="4"
        v-for="(item, index) in products"
        :key="index"
      >
        <v-card
          class="my-2 rounded-lg"
          height="100%"
          v-if="$vuetify.breakpoint.smAndDown"
        >
          <div class="d-flex justify-center flex-column align-center">
            <v-lazy style="width: 100%">
              <div
                :style="{ 'background-image': 'url(' + item.image + ')' }"
                style="
                  height: 160px;
                  background-size: cover;
                  background-position: center;
                "
              ></div>
            </v-lazy>
            <h5 class="text-center mt-4">
              {{ item.product }}
            </h5>

            <v-btn
              color="info"
              class="mt-3"
              depressed
              rounded
              small
              :href="item.url"
            >
              Comprar
            </v-btn>
          </div>
        </v-card>
        <v-card outlined height="100%" class="rounded-lg" v-else>
          <div class="d-flex flex-no-wrap justify-space-between align-center">
            <div>
              <h4 class="ml-4 font-weight-medium">
                <small>{{ item.product }}</small>
              </h4>

              <v-card-actions>
                <v-btn
                  color="info"
                  depressed
                  rounded
                  small
                  :href="item.url"
                  class="ml-1"
                >
                  Comprar
                </v-btn>
              </v-card-actions>
            </div>

            <v-lazy>
              <v-avatar size="160" tile>
                <v-img :src="item.image"></v-img>
              </v-avatar>
            </v-lazy>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped>
.image-card {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.v-card {
  overflow: hidden;
}
* {
  word-break: break-word !important;
}
</style>
<script>
export default {
  name: "HelloWorld",
  props: ["imgSrc"],
  data: () => ({
    //
    products: [
      {
        image: "/images/ecommerce.webp",
        product: "Ecommerce",
        description:
          "Aplicación web que te apoya en la distribución, venta, compra, marketing y suministro de información de productos o servicios a través de Internet.",
        url: "https://tidishop.tidingo.com/inventario/ecommerce",
      },
      {
        image: "/images/landing.webp",
        product: " Landing page",
        description:
          "Una Landing Page es una página desarrollada con el único objetivo de convertir los visitantes en Leads o prospectos de ventas por medio de una oferta determinada.",
        url: "https://tidishop.tidingo.com/inventario/landing-page-de-identidad-corporativa",
      },
      {
        image: "/images/corporate.webp",
        product: "Página corporativa",
        description:
          "Tu página web en linea, el cuál contiene un panel administrativo donde puedes editar la información de tu domicilio, bolsa de trabajo, noticias, la imagen de la página principal, es completamente administrable.",
        url: "https://tidishop.tidingo.com/inventario/tu-pagina-corporativa",
      },
      {
        image: "/images/marketplace.webp",
        product: "Marketplace",
        description:
          "Un mercado en línea (marketplace) es un tipo de sitio web de comercio electrónico en el que la información sobre productos o servicios es proporcionada por múltiples terceros.",
        url: "https://tidishop.tidingo.com/inventario/tu-pagina-corporativa",
      },
      {
        image: "/images/logistica_ventas.webp",
        product: "Logistica ventas",
        description:
          "Aplicación móvil que forma parte del ecosistema nuestro software de logística, funciona como una herramienta de apoyo en ruta para el vendedor, donde observa los puntos de visita, inventario y el detalle de los cortes.",
        url: "https://tidishop.tidingo.com/inventario/ecommerce",
      },
      {
        image: "/images/logistica.webp",
        product: "Software Logística",
        description:
          "Aplicación web que cuenta con una interfaz amigable y sencilla de utilizar, maneja tus listas de precios, productos, clientes, rutas y cortes, distribución de tus ventas en gráficos y obtén reportes de tus clientes, productos y ventas.",
        url: "https://tidishop.tidingo.com/inventario/software-logistica",
      },
      {
        image: "/images/app.webp",
        product: "Tidingo app",
        description:
          "Es una aplicación fácil de utilizar que te permite buscar negocios en tu ciudad y consultar la información que necesites como números de teléfono, dirección, horarios, galería de imágenes y redes sociales. Sin duda una excelente herramienta para incentivar la economía local.",
        url: "https://app.tidingo.com",
      },
      {
        image: "/images/school.webp",
        product: "Landingpage para escuelas",
        description:
          "Una Landing Page es una página desarrollada con el único objetivo de convertir los visitantes en Leads o prospectos de ventas por medio de una oferta determinada enfocada para escuelas.",
        url: "https://tidishop.tidingo.com/inventario/landingpage-para-escuelas",
      },
      {
        image: "/images/chatbot.webp",
        product: "Chatbots",
        description:
          "Conversa 24/7 con todas las personas que visitan tu sitio mientras capturas sus datos de contacto. Genera oportunidades de venta mediante un diálogo personalizado y fluido.",
        url: "https://tidishop.tidingo.com/inventario/chatbot",
      },
      {
        image: "/images/proveedores.webp",
        product: "Software proveedores",
        description:
          "Aplicación móvil que forma parte del ecosistema nuestro software de auditoría, permite que tus proveedores te coticen los productos de su almacén directamente sin intermediarios.",
        url: "https://tidishop.tidingo.com/inventario/tidingo-software",
      },
      {
        image: "/images/credit.webp",
        product: "Landingpage con simulador de crédito",
        description:
          "Una Landing Page es una página desarrollada con el único objetivo de convertir los visitantes en Leads o prospectos de ventas por medio de una oferta determinada, la cuál contiene un simulador de crédito adaptado a tus necesidades.",
        url: "https://tidishop.tidingo.com/inventario/landingpage-con-simulador-de-credito-1",
      },
      {
        image: "/images/auditoria.webp",
        product: "Software auditoria",
        description:
          "Una Landing Page es una página desarrollada con el único objetivo de convertir los visitantes en Leads o prospectos de ventas por medio de una oferta determinada, la cuál contiene un simulador de crédito adaptado a tus necesidades.",
        url: "https://tidishop.tidingo.com/inventario/tidingo-software",
      },
    ],
  }),
};
</script>
